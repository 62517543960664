<template>
    <section class="page login-page layout-login d-flex">
        <div class="d-flex flex-row login-left-right flex-fill">
            <div class="login-page-right login-container">
                <div class="img d-none d-md-block">
                    <img src="@/assets/images/eu5/loginBack2x.png" alt="" />
                </div>
                <div class="img-box-md d-block d-md-none">
                    <img class="loginBackMd" src="@/assets/images/eu5/loginBack2x.png" alt="">
                </div>
                <div class="login-box">
                    <div class="login container ">
                        <div class="login-inner" id="login-page">
                            <loading-indicator v-if="!config" />
                            <template v-else>
                                <create-account-component v-if="config.signupEnabled === true" :config="config" :regions="regions" :referral="id" />
                                <div class="container" v-else>
                                    <div class="row">
                                        <div class="col">
                                            <div class="alert alert-info mt-5">
                                                <h1>系统升级</h1>
                                                <p>为了给您提供更好的服务，我们正对现有系统进行升级。升级过程中，暂停新账号注册。</p>

                                                <hr />
                                                <router-link to="/user/login">已有账号？点击这里登录</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style scoped>
.login-left-right {
  margin: auto;
  width: 100%;
}
.login-container {
  background: #FFFFFF;
  padding: 0 70px;
  display: flex;
  justify-content: space-between;
}
.login-container .login-box {
  background: none;
  width: auto;
  min-width: 45%;
  padding-left: 0;
  position: relative;
}
.login-container .img .tips {
  width: 100%;
  height: 20px;
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 10px;
  color: #FEFFFF;
  line-height: 10px;
  text-align: center;
  font-style: normal;
  background-color:initial;
  margin: 0;
  padding: 0 10px;
  line-height: 16px;
}
.login-container .img .title{
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  line-height: 28px;
  text-align: center;
  padding: 0 15px;
  margin: 15px;
}
.img {
  width: 50%;
  margin: 0;
  flex: 1;
  flex-shrink: 0;
  overflow: hidden;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.login-container > .img img {
  max-width: 80%;
  min-width: 400px;
  height: auto;
  flex: 1;
}
.img-box-md {
  width: 100%;
  height: auto;
  margin-top: 20px;
  text-align: center;
}
.img-box-md .loginBackMd{
  max-width: 400px;
  width: 80%;
  height: auto;
  margin: 0 auto;
  flex: none;
}
.img-box-md .loginPlane {
  max-width: 6rem;
  height: auto;
  position: absolute;
  bottom: -0.625rem;
  left: 60%;
}
.img-box-md .loginBall {
  max-width: 3rem;
  height: auto;
  z-index: 999;
  position: absolute;
  bottom: -1rem;
  right: 10%;
}
@media (max-width: 1024px) {

  .login-container .img {
      display: none !important;
  }

  .login-container .login-box {
      width: 100%;
      background: #FFFFFF;
  }
  .login-page .login h1 {
      color: #000;
  }
  .img{
      display: none;
  }
}
@media (max-width: 768px) {
  .login-container {
      padding: 0;
      display: block;
  }
  .login-container .login-box {
      padding: 0;
      height: auto;
      border-radius: 30px;
      background-color: #fff;
  }
  .login-box .container {
      min-width: auto;
      margin: 0;
      width: 100%;
      padding: 0;
  }
  .login {
      position: static;
      transform: none;
      background: none;
  }
  #login-page {
      margin: 0;
      border-radius: 0;
      padding: 30px 40px;
      max-width: none;
  }
}
</style>

<script>
import { getConfigAsync } from 'utilities/helper';
import CreateAccountComponent from './Components/CreateAccount.vue';

export default {
    components: { CreateAccountComponent },
    props: ['id'],

    data() {
        return {
            config: null,

            // All supported regions
            regions: null
        };
    },

    created() {
        this.initAsync();
    },

    watch: {
        '$i18n.locale': function () {
            this.reloadRegionsAsync();
        }
    },

    methods: {
        initAsync: async function () {
            const self = this;

            let config = await getConfigAsync();
            let resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp) {
                self.regions = resp.data || [];
                self.config = config;
            }
        },

        reloadRegionsAsync: async function () {
            // Read a list of supported regions.
            const resp = await $.callGetApi(self, '/api/v1/config/regions');
            if (resp && resp.data) {
                this.regions = resp.data;
            }
        }
    }
};
</script>