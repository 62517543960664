<template>
    <form
      action="javascript:void(0);"
      method="post"
      class="login-form"
      @submit="do_submit"
    >
      <h1>{{ $t("reset_password.title") }}</h1>
      <div class="form-group">
        <div class="d-flex">
          <region-selector
            v-bind:regions="regions"
            :regionCode="regionCode"
            @change="onRegionCodeChanged"
          />
          <div class="flex-fill">
            <input
              name="Name"
              ref="accountInput"
              v-model="account"
              pattern="\d*"
              type="text"
              class="form-control phone round-right"
              data-val="true"
              data-val-required="*"
              :placeholder="$t('reset_password.label_account')"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="password-box">
          <input
            name="Password"
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            autocomplete="new-password"
            class="form-control"
            data-val="true"
            data-val-required="*"
            :placeholder="$t('reset_password.label_password')"
          />
          <svg
            v-show="!showPassword"
            viewBox="0 0 256 256"
            class="svg-icon password-icon"
            @click="showPassword = !showPassword"
          >
            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
          </svg>
          <svg
            v-show="showPassword"
            viewBox="0 0 256 256"
            class="svg-icon password-icon"
            @click="showPassword = !showPassword"
          >
            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
          </svg>
        </div>
      </div>
      <div class="form-group">
        <div class="password-box">
          <input
            name="ConfirmPassword"
            v-model="confirmPassword"
            :type="showConfirmPassword ? 'text' : 'password'"
            autocomplete="new-password"
            class="form-control"
            data-val="true"
            data-val-required="*"
            data-val-equalto-other="Password"
            data-val-equalto="*"
            :placeholder="$t('reset_password.label_confirm_password')"
          />
          <svg
            v-show="!showConfirmPassword"
            viewBox="0 0 256 256"
            class="svg-icon password-icon"
            @click="showConfirmPassword = !showConfirmPassword"
          >
            <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
          </svg>
          <svg
            v-show="showConfirmPassword"
            viewBox="0 0 256 256"
            class="svg-icon password-icon"
            @click="showConfirmPassword = !showConfirmPassword"
          >
            <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
          </svg>
        </div>
      </div>
      <sms-verification
        :regionCode="regionCode"
        :account="account"
        action="reset-password"
      />
      <div class="form-group">
        <button
          type="submit"
          class="btn btn-submit"
          :class="{ 'btn-completed': completed, 'btn-loading': submitting }"
          :disabled="submitting || completed"
        >
          {{ $t("reset_password.title") }}
        </button>
      </div>
    </form>
</template>

<script>
import RegionSelector from "../../Components/RegionSelectorV2.vue";
import "@/assets/svg/v4.2/v4.2-hidePassword.svg";
import "@/assets/svg/v4.2/v4.2-showPassword.svg";
export default {
  components: { RegionSelector },
  props: ["regions"],
  data() {
    return {
      account: null,
      regionCode: null,
      password: null,
      confirmPassword: null,

      showInputs: true,

      submitting: false,
      completed: false,
      showPassword: false,
      showConfirmPassword: false
    };
  },

  mounted() {
    $.resetValidators();
  },

  methods: {

    do_submit: function (e) {
      const frm = $(e.target);
      const self = this;
      if (self.password !== self.confirmPassword) {
        $.top_error(self.$t("withdraw_password.err_different_passwords"));
        return;
      }
      if (frm.valid()) {
        this.submitting = true;

        const data = frm.serialize();
        $.callPostApi(self, "/api/v1/account/resetpassword", data)
          .then((json) => {
            if (json && json.errcode === 0) {
              // redirect to home page
              self.completed = true;

              setTimeout(function () {
                self.$router.push("/user");
              }, 500);
            } else {
              $.top_error(json.errmsg);
            }
          })
          .catch(function (err) {
            $.top_error(self.$t("general.operation_error"));
          })
          .then(() => {
            self.submitting = false;
          });
      }
    },

    focusAccount: function () {
      this.$refs.accountInput.focus();
    },

    onRegionCodeChanged: function (val) {
      this.regionCode = val;
    }
  }
};
</script>
<style type="text/css" scoped>
.page-top-nav{
    display: flex;
    margin-bottom: 2rem !important;
    align-items: center;
}
.page-top-nav li{
    width: 50%;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
#login-page button.btn-submit {
    margin: 1rem 0;
    display: block;
    width: 100%;
    padding: .625rem 0;
    color: #fff;
    background: #041438;
    border-radius: 234px 234px 234px 234px;
    font-size: 18px;
    line-height: 30px;
}
.help-block {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #7F8490;
}
.form-group {
    margin-bottom: 1.5625rem;
    font-size: 16px;
}
.form-group .form-control {
    font-size: 15px;
    outline: none;
    background: rgba(255,255,255,0.2);
    border-radius: 251px 251px 251px 251px;
    border: 1px solid #7F8490;
}
.form-group .form-control:focus {
    outline: none;
    border-color: #8C89EB;
}
.login-link {
    font-size: 12px;
    color: #7F8490;
}
@media (max-width: 768px) {
    .login-page .form-group {
        margin-bottom: 10px;
    }
    .login-page .form-control {
        border: 1px solid #7F8490;
        border-radius: 441px;
        line-height: 18px;
        background: rgba(255,255,255,0.2);
    }
    #login-page button.btn-submit {
        border-radius: 441px;
        font-size: 16px;
        font-weight: 600;
        background-color: #0202F8;
    }
    .page-top-nav {
        margin-bottom: 1rem !important;
    }
    .page-top-nav li a {
        font-size: 1rem;
    }
    .page-top-nav li.active a {
        font-size: 18px;
    }
}
</style>