<template>
    <div class="container">
        <div class="card-list d-none d-md-block">
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userIfn2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_id_verification') }}</div>
                        <div class="card-item-des">{{ $t('user.label_id_verification_desp') }}</div>
                    </div>
                </div>
                <router-link to="/user/verifyid" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userPhone2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_account') }}</div>
                        <div class="card-item-des">{{ $t('user.label_account') }}</div>
                    </div>
                </div>
                <router-link to="/user/phone" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userEmail2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('register.label_email') }}</div>
                        <div class="card-item-des">{{ $t('register.label_email') }}</div>
                    </div>
                </div>
                <router-link to="/user/email" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item" v-if="sysconfig.requiresWithdrawPassword === true">
                <div class="card-left">
                    <img src="@/assets/images/eu5/payPassword2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_financial_password') }}</div>
                        <div class="card-item-des">{{ $t('withdraw_password.title') }}</div>
                    </div>
                </div>
                <router-link to="/user/changewithdrawpwd" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userGa2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('ga.title') }}</div>
                        <div class="card-item-des">{{$t('ga.title') }}</div>
                    </div>
                </div>
                <router-link to="/user/ga" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userBanlance2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_buy_crypto') }}</div>
                        <div class="card-item-des">{{ $t('user.label_id_verification_desp') }}</div>
                    </div>
                </div>
                <router-link to="/user/balance" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/payBankcard2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{$t('user.label_bankaccount')}}</div>
                        <div class="card-item-des">{{ $t('bank_account.label_confirm') }}</div>
                    </div>
                </div>
                <router-link to="/user/bankaccounts" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userNotices2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('notices.notice_title')}}</div>
                        <div class="card-item-des">{{ $t('notices.notice_title') }}</div>
                    </div>
                </div>
                <router-link to="/notices" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userReset2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_password')}}</div>
                        <div class="card-item-des">{{ $t('reset_password.title') }}</div>
                    </div>
                </div>
                <router-link to="/user/reset" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/futures2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_futures_orders') }}</div>
                        <div class="card-item-des">{{$t('home.header_futures_orders') }}</div>
                    </div>
                </div>
                <router-link to="/futures/openorders" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/exchange2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_exchange_orders') }}</div>
                        <div class="card-item-des">{{$t('home.header_exchange_orders') }}</div>
                    </div>
                </div>
                <router-link to="/exchange/orders" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/options2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_boption_orders') }}</div>
                        <div class="card-item-des">{{$t('home.header_boption_orders') }}</div>
                    </div>
                </div>
                <router-link to="/boption/openorders" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
            <div class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userLoginout2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_signout') }}</div>
                        <div class="card-item-des">{{$t('user.label_signout') }}</div>
                    </div>
                </div>
                <router-link to="/user/signout" class="card-item-button">{{ $t('home.label_more') }}</router-link>
            </div>
        </div>
        <div class="card-list d-block d-md-none">
            <h4>{{ $t('home.footer_services') }}</h4>
            <router-link to="/user/verifyid" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userIfn2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_id_verification') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/phone" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userPhone2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_account') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/email" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userEmail2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('register.label_email') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/changewithdrawpwd" class="card-item" v-if="sysconfig.requiresWithdrawPassword === true">
                <div class="card-left">
                    <img src="@/assets/images/eu5/payPassword2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_financial_password') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/ga" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userGa2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('ga.title') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/banlance" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userBanlance2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_buy_crypto') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/bankaccounts" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/payBankcard2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{$t('user.label_bankaccount')}}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/notices" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userNotices2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('notices.notice_title')}}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/reset" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userReset2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_password')}}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/futures" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/futures2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_futures_orders') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/exchange" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/exchange2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_exchange_orders') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/boptions" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/options2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('home.header_boption_orders') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
            <router-link to="/user/signout" class="card-item">
                <div class="card-left">
                    <img src="@/assets/images/eu5/userLoginout2x.png" alt="" class="card-item-icon">
                    <div class="card-item-box">
                        <div class="card-item-title">{{ $t('user.label_signout') }}</div>
                    </div>
                </div>
                <svg viewBox="0 0 256 256" >
                    <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                </svg>
            </router-link>
        </div>
    </div>
    
</template>

<script type="text/javascript">
export default { 
    props: ['profile'],
    data(){
        return {
            bankaccount:[]
        }
    },
    created(){
        this.initAsync();
    },
    methods:{
        initAsync: async function () {
            // List all accounts with payment source as bank.
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0) {
                this.bankaccount = json.data;
            }
        }
    }
};
  
</script>
<style scoped>
.container {
    box-sizing: border-box;
    padding: 0;
}
.card-list {
    margin-top: 100px;
}
.card-list h4 {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 25px;
    color: #121836;
    line-height: 45px;
    margin-bottom: 40px;
}
.card-item {
    border-radius: 6px 6px 6px 6px;
    border: 1px solid rgba(197,197,197,0.48);
    margin-bottom: 20px;
    display: flex;
    padding: 20px 40px 20px 13px;
    justify-content: space-between;
    align-items: center;
}
.card-item:last-child {
    margin-bottom: 0;
}
.card-item svg {
    height: 20px;
    width: 20px;
}
.card-left {
    display: flex;
    align-items: center;
}
.card-item-icon {
    height: 35px;
    width: auto;
    margin-right: 20px;
}
.card-item-title {
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    line-height: 25px;
    margin-bottom: 5px;
}
.card-item-des {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 20px;
}
.card-item-button {
    border-radius: 387px 387px 387px 387px;
    padding: 6px 27px;
    background: #E5E5E5;
    font-size: 14px;
    color: #767676;
}

.user-info{
    background: #fff;
    color: var(--primary-base);
    padding: 0px;
    height: initial;
    margin-bottom: 0px;
}
.settings-page h1 {
    margin-top: 0px;
}
.real-name svg{
    margin-top: 0px;
    position: relative;
}
.real-name{
    margin-left: 0px;
    padding-left: 1rem;
}
.google .flex-fill{
    margin-left: 10px;
}
.google svg{
    margin-top: 0px;
}
.container {
    margin-bottom: 20px;
}
@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }

    .d-block {
        display: block !important;
    }
    .container {
        background-color: #fff;
        border-radius: 21px;
        padding: 28px;
    }
    .card-item {
        background-color: #fff;
        padding: 10px 10px;
        border: 1px solid rgba(197, 197, 197, 0.48);
    }
    .card-item:last-child {
        margin-bottom: 0;
    }
}
@media (max-width:769px) {
    .card-list {
        margin-top: 0;
    }
}
</style>