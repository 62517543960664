<template>
    <form :class="{ 'd-none': !showInputs }" class="login-form" method="post" action="javascript:void(0);" @submit="createAccount">
        <h1 class="form-title">{{ $t('register.title') }}</h1>
        <!--
        Does both phone number and email are supported?
        -->
        <div class="page-top-nav mb-3" v-if="config.phoneSupported && config.emailSupported">
            <li :class="{ active: !emailVCode }">
                <a @click="emailVCode = false" href="javascript:;">{{ $t('register.label_phone') }}</a>
            </li>
            <li :class="{ active: emailVCode }">
                <a @click="emailVCode = true" href="javascript:;">{{ $t('register.label_email') }}</a>
            </li>
        </div>

        <div class="form-group">
            <template v-if="emailVCode">
                <!-- Email account -->
                <!-- Required by the interface -->
                <input v-model="account" name="Name" type="text" autocomplete="off" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^[^@]+@[^@]{3,}$" class="form-control" :placeholder="$t('register.label_email')" />
            </template>
            <template v-else>
                <!-- Phone account -->
                <div class="d-flex">
                    <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                    <div class="flex-fill">
                        <input v-model="account" name="Name" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                    </div>
                </div>
            </template>
        </div>
        <div class="form-group">
            <div class="password-box">
                <input name="Password" v-model.trim="password" :type="showPassword?'text':'password'" autocomplete="new-password" class="form-control" data-val="true" data-val-required="*" data-val-maxlength="*" data-val-maxlength-max="32" data-val-minlength="*" data-val-minlength-min="2" :placeholder="$t('register.label_password')" />
                <svg v-show="!showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showPassword = !showPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group">
            <div class="password-box">
                <input name="ConfirmPassword" v-model.trim="confirmPassword" :type="showConfirmPassword?'text':'password'" autocomplete="new-password" class="form-control" data-val="true" data-val-required="*" data-val-equalto="*" data-val-equalto-other="Password" data-val-maxlength="*" data-val-maxlength-max="32" data-val-minlength="*" data-val-minlength-min="6" :placeholder="$t('register.label_confirm_password')" />
                <svg v-show="!showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-hidePassword" />
                </svg>
                <svg v-show="showConfirmPassword" viewBox="0 0 256 256" class="svg-icon password-icon" @click="showConfirmPassword = !showConfirmPassword">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-showPassword" />
                </svg>
            </div>
        </div>
        <div class="form-group" v-if="emailVCode">
            <div class="d-flex">
                <region-selector :regions="regions" :regionCode="regionCode" @change="onRegionChanged" />
                <div class="flex-fill">
                    <input v-model="Phone" name="Phone" type="text" autocomplete="off" pattern="\d*" data-val="true" data-val-required="*" data-val-regex="*" data-val-regex-pattern="^0?[1-9]\d+$" class="form-control round-right" :placeholder="$t('register.label_phone')" />
                </div>
            </div>                
        </div>
        <div class="form-group">
            <div class="code">
                <input type="text" name="ReferralCode" v-model="refCode" autocomplete="off" class="form-control" data-val="true" data-val-required="*" :placeholder="$t('register.label_referral_code')" />
            </div>
        </div>

        <!-- Do we need SMS verification? or just use a simple image verification code? -->
        <sms-verification v-if="config.signupVCodeRequired" :emailVCode="emailVCode" :regionCode="regionCode" :account="account" action="signup" />
        <template v-else>

            <!-- Use a dummy sms code to make the API happy. -->
            <input type="hidden" name="Code" value="000000" />
        </template>

        <button type="submit" class="btn btn-submit" :class="{ 'btn-completed': completed, 'btn-loading': submitting }" :disabled="submitting || completed">
            {{ $t('register.label_register') }}
        </button>
        <div class="text-center form-group">
            <router-link class="login-link" to="/user/login">{{ $t('register.label_login') }}</router-link>
        </div>
    </form>
</template>

<script>
import RegionSelector from '../../Components/RegionSelectorV2.vue';
import { setProfile } from 'utilities/helper';
import '@/assets/svg/v4.2/v4.2-hidePassword.svg';
import '@/assets/svg/v4.2/v4.2-showPassword.svg';
export default {
    components: { RegionSelector },
    props: ['config', 'regions', 'referral'],

    data() {
        return {
            regionCode: null,
            account: null,
            password: null,
            confirmPassword: null,
            refCode: null,
            showInputs: true,

            // Submit status
            submitting: false,
            completed: false,

            // which types of accounts are supported?
            emailVCode: false,
            Phone:null,
            showPassword:false,
            showConfirmPassword:false
        };
    },

    created() {
        const config = this.config;
        if (!config || config.signupEnabled !== true) throw new Error('signupEnabled must be true when creating new accounts.');

        const regions = this.regions;
        if (!Array.isArray(regions)) throw new Error('An array of supported regions are required.');

        this.refCode = this.referral;
    },

    watch: {
        referral: function () {
            this.refCode = this.referral;
        },

        emailVCode: function () {
            Vue.nextTick(() => {
                $.resetValidators();
            });
        }
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        createAccount: function (e) {
            const frm = $(e.target);
            if (this.password !== this.confirmPassword) {
                $.top_error(this.$t("withdraw_password.err_different_passwords"));
                return;
            }
            if (!frm.valid()) return false;

            // post the server
            const data = frm.serialize();
            const self = this;
            self.submitting = true;

            $.callPostApi(self, '/api/v1/account/create', data)
                .then((json) => {
                    if (json && json.errcode === 0) {
                        try {
                            // Save the current authenticated user.
                            setProfile(json.data);

                            // redirect to home page
                            self.completed = true;

                            setTimeout(function () {
                                if (self.config.requiresWithdrawPassword === true) {
                                    self.$router.push('/user/changewithdrawpwd?skip=true');
                                } else {
                                    self.$router.push('/user/verifyid');
                                }
                            }, 500);
                        } catch (err) {
                            console.error(err);
                        }
                    }else if(json.errcode === 103){
                        // 什么也不做，保持loading状态
                    } else {
                        self.submitting = false;
                        $.top_error(json.errmsg);
                    }
                })
                .catch(function (err) {
                    self.submitting = false;
                    $.top_error(self.$t('general.operation_error'));
                })
        },

        onRegionChanged: function (val) {
            console.log(`## Region changed to ${val}`);
            this.regionCode = val;
        }
    }
};
</script>
<style type="text/css" scoped>
.page-top-nav{
    display: flex;
    margin-bottom: 2rem !important;
    align-items: center;
}
.page-top-nav li{
    width: 50%;
    padding: 0;
    text-align: center;
    box-sizing: border-box;
}
.page-top-nav li a {
    display: inline-block;
    padding: 0.5rem 0.75rem;
    height: 100%;
}
.password-box {
    position: relative;
}
.password-icon {
    position: absolute;
    right: 15px;
    bottom: 50%;
    transform: translateY(50%);
    cursor: pointer;
}
#login-page button.btn-submit {
    margin: 1rem 0;
    display: block;
    width: 100%;
    padding: .625rem 0;
    color: #fff;
    background: #041438;
    border-radius: 234px 234px 234px 234px;
    font-size: 18px;
    line-height: 30px;
}
.help-block {
    opacity: 1;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #7F8490;
}
.form-group {
    margin-bottom: 1.5625rem;
    font-size: 16px;
}
.form-group .form-control {
    font-size: 15px;
    outline: none;
    background: rgba(255,255,255,0.2);
    border-radius: 251px 251px 251px 251px;
    border: 1px solid #7F8490;
}
.form-group .form-control:focus {
    outline: none;
    border-color: #8C89EB;
}
.login-link {
    font-size: 12px;
    color: #7F8490;
}
@media (max-width: 768px) {
    .login-page .form-group {
        margin-bottom: 10px;
    }
    .login-page .form-control {
        border: 1px solid #7F8490;
        border-radius: 441px;
        line-height: 18px;
        background: rgba(255,255,255,0.2);
    }
    #login-page button.btn-submit {
        border-radius: 441px;
        font-size: 16px;
        font-weight: 600;
        background-color: #0202F8;
    }
    .page-top-nav {
        margin-bottom: 1rem !important;
    }
    .page-top-nav li a {
        font-size: 1rem;
    }
    .page-top-nav li.active a {
        font-size: 18px;
    }
}
</style>
